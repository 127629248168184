import store from "@state/store"
import CustomAttrs from "@mixins/custom-attributes"
import {
  DataProviderSources,
  IntegrationDataSources,
} from "@/src/utils/mixins/data-sources.js"
import { ConnectionUseCase, SubscriptionTypes } from "@mixins/connections"
import { ContextViewStates } from "@mixins/app-contexts"
import { AnalyticsEventTypes } from "@mixins/analytics"
import router from "./index"

// Make sure that the "next" function is called exactly once in any given pass through the
// navigation guard. It can appear more than once, but only if the logical paths have no
// overlap, otherwise the hook will never be resolved or produce errors.

export default [
  {
    path: "/",
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
          next(defaultRoute)
        })
      },
    },
  },
  {
    path: "/index.html",
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
          next(defaultRoute)
        })
      },
    },
  },

  // ==============================================
  // APP CONTEXTS
  // ==============================================

  // ===
  // Group
  // ===

  {
    path: "/group",
    component: () =>
      lazyLoadView(import("@views/context-views/group-view-wrapper")),
    children: [
      {
        path: "",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
              next(defaultRoute)
            })
          },
        },
      },
      {
        path: ":permalink",
        name: "group-view",
        beforeEnter(routeTo, routeFrom, next) {
          if (_.isEmpty(_.get(routeTo, "params.context_view"))) {
            store
              .dispatch("contextViews/getRouteByPermalinkFor", {
                parent_type: "Group",
                parent_id: _.get(store.getters["auth/activeGroup"], "id"),
                permalink: _.get(routeTo, "params.permalink"),
                permalink_fallback: _.get(
                  store.getters["auth/sessionObject"],
                  "session.default_context_permalink"
                ),
              })
              .then((defaultRoute) => {
                routeTo.params.context_view = defaultRoute.params.context_view
                next()
              })
              .catch((e) => {
                next({ name: "404" })
                console.error(e)
              })
          } else {
            next()
          }
        },
      },
    ],
  },

  // ==============================================
  // STANDALONE ROUTES (Show pages, Defaults, etc.)
  // ==============================================

  // ===
  // Companies
  // ===
  {
    path: "/companies",
    component: () =>
      lazyLoadView(import("@views/companies/companies-show-wrapper")),
    children: [
      {
        path: "",
        beforeResolve(routeTo, routeFrom, next) {
          store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
            next(defaultRoute)
          })
        },
      },
      {
        path: ":id",
        name: "company",
        component: () =>
          lazyLoadView(import("@views/companies/companies-show")),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            const activeGroup = store.getters["auth/activeGroup"]
            const params = activeGroup
              ? "?q=" +
                JSON.stringify({
                  custom_attrs: [
                    CustomAttrs.isAccessibleInGroup(activeGroup.id),
                    CustomAttrs.isInGroup("company", activeGroup.id),
                    CustomAttrs.groupModelId("company", activeGroup.id),
                  ],
                })
              : ""
            store
              .dispatch("companies/show", {
                model: "company",
                id: routeTo.params.id,
                params: params,
              })
              .then((company) => {
                if (company) {
                  routeTo.params.company = company
                  next()
                } else {
                  next({ name: "404", params: { resource: "Company" } })
                }
              })
              .catch(() => {
                next({ name: "404", params: { resource: "Company" } })
              })
          },
        },
        props: (route) => ({ company: route.params.company }),
      },
    ],
  },

  // ===
  // Reports
  // ===

  {
    path: "/reports",
    component: () =>
      lazyLoadView(import("@views/reports/reports-index-wrapper")),
    children: reportChildren(false),
  },

  // ===
  // Containers
  // ===
  {
    path: "/containers",
    component: () =>
      lazyLoadView(import("@views/containers/containers-view-wrapper")),
    beforeEnter(routeTo, routeFrom, next) {
      if (
        ["containers-integrations", "containers-data-providers"].includes(
          routeTo.name
        )
      ) {
        next()
      } else {
        next({ name: "containers-integrations" })
      }
    },
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (
          ![
            "containers-integrations",
            "containers-data-providers",
            "containers-show",
          ].includes(_.get(routeFrom, "name"))
        ) {
          store
            .dispatch("connections/fetchConnections", { reload: true })
            .catch((e) => {
              console.error(e)
            })
            .finally(() => {
              next()
            })
        } else {
          next()
        }
      },
    },
    children: [
      {
        path: "integrations",
        name: "containers-integrations",
        component: () => lazyLoadView(import("@views/containers/integrations")),
      },
      {
        path: "data-providers",
        name: "containers-data-providers",
        component: () =>
          lazyLoadView(import("@views/containers/data-providers")),
      },
    ],
  },
  {
    path: "/containers/:id",
    name: "containers-show",
    component: () => lazyLoadView(import("@views/containers/containers-show")),
    props: (route) => ({
      container: route.params.container,
      id: route.params.id,
    }),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // Check if container is in existing loaded connections
        let cachedConnections = store.getters["connections/connections"]
        let cachedConnection = null
        if (cachedConnections) {
          cachedConnection = _.find(cachedConnections, {
            word: routeTo.params.id,
          })
        }
        if (cachedConnection) {
          routeTo.params.container = cachedConnection
          next()

          // Otherwise load all connections and try to find the correct one
        } else {
          store
            .dispatch("connections/fetchConnections", { reload: true })
            .then((c) => {
              let container = _.find(c, {
                word: routeTo.params.id,
              })

              if (!_.isEmpty(container)) {
                routeTo.params.container = container
                next()
              } else {
                next({
                  name: "404",
                  params: { resource: "Container" },
                })
              }
            })
            .catch((e) => {
              console.error(e)
              next({
                name: "404",
                params: { resource: "Container" },
              })
            })
        }
      },
    },
  },
  // ===

  // ===
  // Share
  // ===

  {
    path: "/share/:secret/access-code",
    name: "set_access_code",
    component: () => lazyLoadView(import("@views/auth/set-access-code.vue")),
    meta: {
      skipAuth: true,
    },
  },

  {
    path: "/share/:secret/reports",
    component: () =>
      lazyLoadView(import("@views/reports/reports-index-wrapper")),
    meta: {
      skipAuth: true,
    },
    children: reportChildren(true),
  },

  // ===
  // Users
  // ===

  {
    path: "/members",
    component: () => lazyLoadView(import("@views/users/users-show-wrapper")),
    children: [
      {
        path: "",
        name: "members",
        component: () =>
          lazyLoadView(import("@views/users/users-index-wrapper")),
      },
      {
        path: ":id",
        name: "user",
        component: () => lazyLoadView(import("@views/users/users-show")),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            const activeGroup = store.getters["auth/activeGroup"]
            const activeNetwork = store.getters["auth/activeNetwork"]
            const params = activeGroup
              ? JSON.stringify({
                  custom_attrs: [
                    CustomAttrs.isAdmin(activeNetwork),
                    CustomAttrs.isAdmin(activeGroup),
                  ],
                })
              : ""

            store
              .dispatch("users/fetchUser", {
                id: routeTo.params.id,
                params: params,
              })
              .then((user) => {
                if (user) {
                  routeTo.params.user = user
                  next()
                } else {
                  next({ name: "404", params: { resource: "User" } })
                }
              })
              .catch(() => {
                next({ name: "404", params: { resource: "User" } })
              })
          },
        },
        props: (route) => ({ user: route.params.user }),
      },
    ],
  },

  // ===
  // Connections
  // ===

  {
    path: "/connections",
    component: () =>
      lazyLoadView(import("@views/connections/connections-index-wrapper")),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!_.isEmpty(store.getters["auth/activeGroup"])) {
          // Lite Groups
          if (!store.getters["auth/activeGroup"].srm_enabled) {
            store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
              next(defaultRoute)
            })
          }
          // Full Groups
          else if (_.isEmpty(store.getters["connections/connections"])) {
            store
              .dispatch("connections/initConnections", { reload: true })
              .finally(() => {
                next()
              })
          } else {
            next()
          }
        } else {
          next()
        }
      },
    },
    children: [
      {
        path: "",
        name: "connections",
        component: () =>
          lazyLoadView(import("@views/connections/connections-index")),
      },
      {
        path: ":id",
        name: "connection",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            let connections = store.getters["connections/connections"]

            let routeId = routeTo.params.id
            let activeConnection =
              _.find(connections, { word: routeId }) ||
              _.find(connections, { id: routeId }) ||
              _.find(connections, { all: true })

            // Flatfile Import Records (ie. "ir") should open the import dialog
            // and no longer be framed as a connection. Opening the dialog is
            // handled in the Connections store "handleTransitionTo" logic.
            if (!_.isEmpty(activeConnection) && routeId !== "ir") {
              store.dispatch(
                "connections/setActiveConnection",
                activeConnection
              )
              // ===
              // Data Provider Connections
              // ===
              if (
                _.includes(
                  DataProviderSources,
                  _.get(activeConnection, "data_source")
                )
              ) {
                router
                  .push({
                    name: "containers-show",
                    params: { id: _.get(activeConnection, "word") },
                  })
                  .catch()
              }
              // ===
              // Integration Connections
              // ===
              else if (
                _.includes(
                  IntegrationDataSources,
                  _.get(activeConnection, "data_source")
                )
              ) {
                store.dispatch(
                  "contextViews/transitionToContainersWithFilter",
                  { word: "all-integrations" }
                )
              }
              // ===
              // Group Connections
              // ===
              else if (
                _.includes(
                  [
                    ConnectionUseCase.GROUP_ACTIVE,
                    ConnectionUseCase.GROUP_SUBSCRIPTIONS,
                  ],
                  _.get(activeConnection, "use_case")
                )
              ) {
                let word
                // If it is the current group or a network
                if (
                  _.get(activeConnection, "use_case") ===
                    ConnectionUseCase.GROUP_ACTIVE ||
                  SubscriptionTypes.INACTIVE_SUBSCRIPTION ===
                    _.get(activeConnection, "subscription_type") ||
                  _.get(activeConnection, "model_type") === "Network"
                ) {
                  word = "all"
                  // If the group is recieving data from the subscription and its active
                } else if (
                  _.includes(
                    [
                      SubscriptionTypes.READER_AND_PUBLISHER,
                      SubscriptionTypes.PUBLISHER,
                    ],
                    _.get(activeConnection, "subscription_type")
                  )
                ) {
                  word = _.get(activeConnection, "word")
                }
                word = word ? word : "created-reports"
                store.dispatch(
                  "contextViews/transitionToContainersWithFilter",
                  { word: word }
                )
              } else {
                routeTo.params.connection = activeConnection
                next()
              }
            } else {
              next({ name: "404", params: { resource: "Connection" } })
            }
          },
        },
        props: (route) => ({ connection: route.params.connection }),
      },
    ],
  },

  // ===
  // Following
  // ===

  {
    path: "/following",
    component: () =>
      lazyLoadView(import("@views/following/follows-index-wrapper")),
    children: [
      {
        path: "",
        redirect: { name: "follows-companies" },
      },
      {
        path: "relationships",
        name: "follows-companies",
        component: () => lazyLoadView(import("@views/following/follows-index")),
      },
      {
        path: "reports",
        name: "follows-reports",
        component: () => lazyLoadView(import("@views/following/follows-index")),
      },
      {
        path: "groups",
        name: "follows-groups",
        component: () => lazyLoadView(import("@views/following/follows-index")),
      },
      {
        path: "*",
        redirect: { name: "follows-companies" },
      },
    ],
  },

  // ===
  // Groups
  // ===

  {
    path: "/groups",
    component: () => lazyLoadView(import("@views/groups/groups-show-wrapper")),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const findInConnections = (retry = true) => {
          let cachedConnections = store.getters["connections/connections"]
          let cachedGroup = null
          if (cachedConnections) {
            cachedGroup = _.find(cachedConnections, {
              word: routeTo.params.id,
            })
          }

          if (cachedGroup) {
            routeTo.params.group = cachedGroup
            next()
          } else if (retry) {
            setTimeout(() => {
              findInConnections(false)
            }, 300)
          } else {
            next({
              name: "404",
              params: { resource: "Group" },
            })
          }
        }

        findInConnections()
      },
    },
    children: [
      {
        path: ":id",
        name: "group",
        component: () => lazyLoadView(import("@views/groups/groups-show")),
        children: [
          {
            path: "relationships",
            name: "group-relationships",
          },
          {
            path: "reports",
            name: "group-reports",
          },
          {
            path: "activity",
            name: "group-activity",
          },
        ],
      },
    ],
  },

  // ===
  // Settings
  // ===

  {
    // User Settings
    path: "/settings",
    children: [
      {
        path: "",
        name: "UserSettings",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            if (!_.isEmpty(store.getters["auth/activeGroup"])) {
              store
                .dispatch("auth/getDefaultRoute")
                .then((defaultRoute) => {
                  next(defaultRoute)
                })
                .finally(() => {
                  store.dispatch("app/setActiveDialog", {
                    title: "Settings",
                    dialog: "activeFullscreen",
                    action: "toggleDialog",
                    context: "SettingsWrapper",
                    icon: "settings",
                    meta: {
                      skipToSessionUserSettings: true,
                    },
                  })
                })
            } else {
              next()
            }
          },
        },
      },
      {
        path: "*",
        redirect: { name: "UserSettings" },
      },
    ],
  },

  // ===
  // Default Routes
  // ===

  {
    path: "/signin",
    name: "signin",
    props: true,
    component: () => lazyLoadView(import("@views/auth/signin")),
    meta: {
      skipAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/sessionObject"]) {
          store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
            next(defaultRoute)
          })
        } else if (_.isEqual(_.get(routeTo, "query.auth"), "sso")) {
          next({ name: "sso-login-enabled" })
        } else {
          next()
        }
      },
    },
  },
  {
    path: "/signin/:id",
    name: "sso-login",
    component: () => lazyLoadView(import("@views/auth/sso-login")),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/privacy-consent",
    name: "privacy-consent",
    component: () => lazyLoadView(import("@views/auth/data-privacy")),
    meta: {
      skipInitCallbacks: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/privacyConsentDate"]) {
          store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
            _.set(defaultRoute, "query.redirectFrom", routeTo.fullPath)
            next(defaultRoute)
          })
        } else {
          next()
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => lazyLoadView(import("@views/auth/reset-password-email")),
    meta: {
      skipAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/sessionObject"]) {
          store.dispatch("auth/getDefaultRoute").then((defaultRoute) => {
            next(defaultRoute)
          })
        } else {
          next()
        }
      },
    },
  },
  {
    path: "/password-reset/:id",
    component: () => lazyLoadView(import("@views/auth/create-new-password")),
    meta: {
      skipAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch("timedAction/fetchTimedAction", {
            secret: routeTo.params.id,
          })
          .then((timedAction) => {
            routeTo.params.timedAction = timedAction
            next()
          })
          .catch(() => {
            next({ name: "signin" })
          })
      },
    },
  },
  {
    path: "/invite-user/:id",
    name: "invite-user",
    component: () => lazyLoadView(import("@views/users/invite-user")),
    meta: {
      skipAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch("timedAction/fetchTimedActionUserInvite", {
            secret: routeTo.params.id,
          })
          .then((timedAction) => {
            if (timedAction.performed_on) {
              next("/")
            } else {
              routeTo.params.timedAction = timedAction
              next()
            }
          })
          .catch((e) => {
            next("/")
            console.error(e)
          })
      },
    },
  },
  {
    path: "/sso-new-user",
    name: "sso-new-user",
    component: () => lazyLoadView(import("@views/users/sso-new-user")),
    meta: {
      skipInitCallbacks: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      skipInitCallbacks: true,
      beforeResolve(routeTo, routeFrom, next) {
        const authSkippedOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authSkipped
        )

        store.dispatch("auth/logOut")
        next(authSkippedOnPreviousRoute ? { ...routeFrom } : { name: "signin" })
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => lazyLoadView(import("@views/base/_404")),
    props: true,
  },
  {
    path: "/503",
    name: "503",
    component: () => lazyLoadView(import("@views/base/_503")),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/pardon-the-interruption",
    name: "server-unavailable",
    component: () => lazyLoadView(import("@views/base/_server-unavailable")),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/sso-login",
    name: "sso-login-enabled",
    component: () => lazyLoadView(import("@views/base/_sso-login")),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/swap-groups",
    name: "swap-groups",
    props: true,
    component: () => lazyLoadView(import("@views/auth/swap-groups")),
    meta: {
      skipInitCallbacks: true,
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@views/base/_loading").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 200,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@views/base/_timeout").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 30000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

function reportChildren(share) {
  return [
    {
      path: "",
      name: share ? "share-reports" : "reports",
      beforeEnter(routeTo, routeFrom, next) {
        if (share) {
          next("/")
        } else {
          store.dispatch("contextViews/handleContextViewTransition", {
            parent_id: _.get(store.getters["auth/activeGroup"], "id"),
            parent_type: "Group",
            context_type: ContextViewStates.REPORTS,
          })
        }
      },
    },
    {
      path: ":id/views/:permalink",
      name: share ? "share-report-view" : "report-view",
      component: () =>
        lazyLoadView(import("@views/context-views/report-view-wrapper")),
      beforeEnter(routeTo, routeFrom, next) {
        if (_.isEmpty(_.get(routeTo, "params.context_view"))) {
          store
            .dispatch("contextViews/getRouteByPermalinkFor", {
              parent_type: "Report",
              parent_id: _.get(routeTo, "params.id"),
              permalink: _.get(routeTo, "params.permalink"),
              permalink_fallback: _.get(routeTo, "params.permalink_fallback"),
            })
            .then((defaultRoute) => {
              next({
                name: defaultRoute.name,
                params: {
                  ...routeTo.params,
                  ...defaultRoute.params,
                },
                meta: {
                  ...routeTo.meta,
                  ...defaultRoute.meta,
                },
                query: {
                  ...routeTo.query,
                },
              })
            })
            .catch(() => {
              next({ name: "404" })
            })
        } else {
          next()
        }
      },
      meta: {
        skipAuth: share,
        beforeResolve(routeTo, routeFrom, next) {
          store
            .dispatch("reports/findCacheOrLoadCustomShow", {
              id: routeTo.params.id,
            })
            .then((report) => {
              routeTo.params.report = report
              // Log events if not navigating within same report.
              if (!_.isEqual(report.id, _.get(routeFrom, "params.report.id"))) {
                // Internal Analytics
                store.dispatch("analytics/logEvent", {
                  event: AnalyticsEventTypes.REPORT_VIEW,
                  child_one_id: report.id,
                  child_one_type: "Report",
                })
                // Amplitude
                store.dispatch("amplitude/logEvent", {
                  name: "View Report",
                  properties: {
                    report_id: report.id,
                    report_name: report.name,
                    public_link_active: report.public_link_active,
                    can_edit: report.can_edit,
                  },
                })
              }

              next()
            })
            .catch((e) => {
              console.error(e)
              next({ name: "404", params: { resource: "Report" } })
            })
        },
      },
      props: (route) => ({ report: route.params.report }),
    },
    // Legacy support of "Report" links, redirect to ReportView.
    {
      path: ":id",
      redirect: (route) => ({
        name: share ? "share-report-view" : "report-view",
        params: route.params,
      }),
    },
    {
      path: ":id/*",
      redirect: (route) => ({
        name: share ? "share-report-view" : "report-view",
        params: route.params,
      }),
    },
  ]
}
